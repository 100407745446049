import cx from 'classnames';
import { type ReactElement } from 'react';

import { navigationConfig } from '@hubcms/brand';
import { useNavigation } from '@hubcms/data-access-navigation';
import { type NavItem } from '@hubcms/domain-navigation';
import { Center } from '@hubcms/ui-center';
import { NavigationLogo } from '@hubcms/ui-navigation-2';
import { getNavigationVersion } from '@hubcms/utils-navigation';

import { NavigationItem } from '../NavigationItem';

import styles from './header.module.scss';

type HeaderDesktopTopicsProps = {
  navigationItems: NavItem[];
};

export function HeaderDesktopTopics({ navigationItems }: HeaderDesktopTopicsProps) {
  const Layout = navigationConfig.logoPosition === 'center' ? TopicsLayoutCenter : TopicsLayoutLeft;

  const { advertisement } = useNavigation();
  const navigationVersion = getNavigationVersion();

  return (
    <Center
      className={styles.topics}
      data-testid="header-desktop-topics"
      maxInlineSize="min(var(--grid-wrap-fluid), var(--max-content-width))"
    >
      <Layout
        nav={
          <nav className={styles.navigationItems}>
            {navigationItems.map(navigationItem => (
              <NavigationItem
                key={navigationItem.uniqueId}
                navItem={navigationItem}
                trackingBlock="topics"
                className={styles.topicsLink}
              />
            ))}
          </nav>
        }
        sales={navigationVersion === '2' && advertisement ? <div id={`ad_${advertisement.slot}`}></div> : undefined}
      />
    </Center>
  );
}

type HeaderDesktopTopicsLayoutProps = {
  className?: string;
  nav: ReactElement;
  sales?: ReactElement;
};

function TopicsLayoutCenter({ className, nav, sales }: HeaderDesktopTopicsLayoutProps) {
  return (
    <div className={cx(className, styles.center)}>
      <div className={styles.navigation}>{nav}</div>

      <NavigationLogo logoName={navigationConfig.logoTopics} size={navigationConfig.logoTopicsSize || 'lg'} />

      <div className={styles.sales}>{sales}</div>
    </div>
  );
}

function TopicsLayoutLeft({ className, nav, sales }: HeaderDesktopTopicsLayoutProps) {
  return (
    <div className={cx(className, styles.left)}>
      <div className={styles.navigation}>
        <NavigationLogo logoName={navigationConfig.logoTopics} size={navigationConfig.logoTopicsSize || 'md'} />

        {nav}
      </div>

      <div className={styles.sales}>{sales}</div>
    </div>
  );
}
